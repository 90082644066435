import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../../../services/base-service';
import { CasesModel } from '../model/cases-model';
import { Observable, of, throwError } from 'rxjs';
const baseApiUrl = '';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class CasesService extends BaseService<CasesModel> {
Internalcase:boolean
  constructor(http: HttpClient) {
    super(http);
  }

  saveCase(data) {
    let post_url =''
    if(this.Internalcase){
       post_url = baseApiUrl + 'internalCase/save';
    }else{
      post_url = baseApiUrl + 'case/save';
    }
    
    return this.http.post<any>(post_url, data);
  }

  getresolutionreason() {
    const get_url = baseApiUrl + 'resolutionReasons/all';
    return this.http.get<any>(get_url);
  }
  getTicketcustomerById(id) {
    const get_url = baseApiUrl + 'case/runningTicketsOfCustomer/' + id;
    return this.http.get<any>(get_url);
  }
  getcustomerById(id) {
    const get_url = baseApiUrl + 'subscriber/getBasicCustDetails/' + id;
    return this.http.get<any>(get_url);
  }
  getCaseById(id) {
    let get_url =''
    if(this.Internalcase){
       get_url = baseApiUrl +'internalCase/' + id;
    }else{
       get_url = baseApiUrl + 'case/' + id;
    }
    return this.http.get<any>(get_url);
  }
  getcaseofcustomer(obj) {
    
    const post_url = baseApiUrl + 'case/getPreviousCaseByCaseFor';
    return this.http.post<any>(post_url,obj);
  }
  getCaseBySwitch() {
    const get_url = baseApiUrl + 'case/getMyCases/';
    return this.http.get<any>(get_url);
  }
  updateList(data) {
    const updateListFromdata = new FormData();
    updateListFromdata.append("caseUpdate", "" + JSON.stringify(data));
    let post_url ='';
    if(this.Internalcase){
       post_url = baseApiUrl + 'internalCase/updateDetails';
    }else{
       post_url = baseApiUrl + 'case/updateDetails';
    }
    return this.http.post<any>(post_url, updateListFromdata);
  }
  caseLiveNetworkDetails(obj) {
    const post_url = baseApiUrl + 'NetworkDevice/refreshOnuDevice';
    return this.http.post<any>(post_url, obj);
  }
  getcasebystatus(status) {
    const get_url = baseApiUrl + 'case/getCasesByStatus/' + status;
    return this.http.get<any>(get_url);
  }
  getModuleName(): string {
    if(this.Internalcase){
      return 'internalCase';   
    }else{
      return 'case';
    }
    
  }

  caseAssigneToMe(id) {
    let get_url = ''
    if(this.Internalcase){
       get_url = baseApiUrl + 'internalCase/assignedTo/' + id;
    }else{
       get_url = baseApiUrl + 'case/assignedTo/' + id;
    }
    return this.http.get<any>(get_url);
  }

  excelExportMyCases(): Observable<Blob> {
    const get_url = baseApiUrl + 'case/excel/mycases';
    return this.http.get<any>(get_url, { observe: 'body', responseType: 'blob' as 'json' });
  }

  excelExportCase(): Observable<Blob> {
    const get_url = baseApiUrl + 'case/excel';
    return this.http.get<any>(get_url, { observe: 'body', responseType: 'blob' as 'json' });
  }

  validate(): boolean {
    return false;
  }

  getCaseAdvanceFilter(obj) {
    let post_url = ''
    if(this.Internalcase){
       post_url = baseApiUrl + 'internalCase';
    }else{
       post_url = baseApiUrl + 'case';
    }
    return this.http.post<any>(post_url, obj, { headers: httpOptions.headers });
  }

  getExelDownloadByfilter(obj?): Observable<Blob> {
    let post_url = ''
    if(this.Internalcase){
       post_url = baseApiUrl + 'internalCase/excelWithFilter';
    }else{
       post_url = baseApiUrl + 'case/excelWithFilter';
    }
    return this.http.post<any>(post_url, obj, { observe: 'body', responseType: 'blob' as 'json' });
  }

  getLeaseOrNormalCustomerBasicDetails(id:any,name){
    let data = { id: id,name:name }
    const post_url = baseApiUrl + 'case/getLeaseOrNormalCustomerBasicDetails';
    return this.http.post<any>(post_url, data);
  }

  getpropertydetails(leasecustId: any): Observable<any> {
    let data = { id: leasecustId }
    const post_url = baseApiUrl + 'leasedlinesubscriber/getAllPropertyByLeaseLine';
    return this.http.post<any>(post_url, data);

  }
  getcircuitdetails(leaseid) {
    const get_url = baseApiUrl + 'leasedlinesubscriber/getAllCircuitByLeaseLine/' + leaseid;
    return this.http.get<any>(get_url);
  }

  getquickCaseType(){
    const get_url = baseApiUrl + 'commonList/generic/quickCaseType';
    return this.http.get<any>(get_url);
  }
  getteams(){
    const get_url = baseApiUrl + 'teams/all';
    return this.http.get<any>(get_url);
  }
  getgroup(){
    const get_url = baseApiUrl + 'group/all';
    return this.http.get<any>(get_url);
  }
  getTeamByGroupid(id){
    const get_url = baseApiUrl + 'teams/getTeamsByGroupId/' + id;
    return this.http.get<any>(get_url);
  }
  deleteUpdateDetails(id){
    const get_url = baseApiUrl + 'caseUpdate/deleteUpdate/' + id;
    return this.http.get<any>(get_url);
  }

  getETPLforNavigateId(caseOriginType,caseNumber){
    return this.http.get<any>(baseApiUrl+ 'case/getEntityByTicketNoAndSource/'+caseOriginType+'/'+caseNumber);
  }

  updateBulkList(data,idList?) {
    //const updateListFromdata = new FormData();
    // updateListFromdata.append("caseUpdate", "" + JSON.stringify(data));
    // updateListFromdata.append("caseId","" + JSON.stringify(idList))
    let post_url ='';
    if(this.Internalcase){
       post_url = baseApiUrl + 'case/bulkCaseUpdate';
    }else{
       post_url = baseApiUrl + 'case/bulkCaseUpdate';
    }
    return this.http.post<any>(post_url, data);
  }

  public getData(id): Promise<any> {
    const get_url = baseApiUrl + 'case/' + id;
    return new Promise((resolve, reject) => {
      this.http.get(get_url).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  downLoadCaseFile(id):Observable<any> {
    const get_url = baseApiUrl + 'download/bulkupload/csv/'+ id;
    return this.http.get(get_url, {responseType: 'blob'});
  }

  downloadFile(name):Observable<any> {
    const get_url = baseApiUrl + 'case/downloadDoc/'+ name;
    return this.http.get(get_url, {responseType: 'blob'});
  }
} 